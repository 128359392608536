export type ConsentCategory =
  | "cookie_cat_functional"
  | "cookie_cat_statistic"
  | "cookie_cat_marketing";

declare global {
  interface Window {
    CookieInformation?:
      | {
          getConsentGivenFor: (category: ConsentCategory) => boolean;
        }
      | "disabled";
  }
}

export const getCookiesConsent = (category: ConsentCategory) => {
  try {
    if (
      (typeof window.CookieInformation === "object" &&
        window.CookieInformation !== null &&
        window.CookieInformation.getConsentGivenFor(category)) ||
      window.CookieInformation === "disabled"
    ) {
      return true;
    }
  } catch (error) {
    console.error("Something wrong with cookie consent:", error); // eslint-disable-line no-console
  }

  return false;
};
