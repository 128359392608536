import * as React from "react";
import {
  Box,
  Button,
  EmptyBox,
  Flex,
  IconAdd,
  IconVideocam,
  styled,
  Text,
} from "@boligportal/juice";
import { css } from "@emotion/react";

const StyledEmptyBox = styled(EmptyBox)`
  height: 100%;
`;

const UploadTrigger = ({
  buttonLabel,
  supportedFileTypesLabel,
  supportedFileTypeNames,
  uploadHelpLabel,
  onFilesChange,
  onFileChange,
  onUploadClicked,
  accept,
  multiple = false,
}: {
  accept: "image/*" | "video/*" | "image/*, video/*";
  buttonLabel: string;
  uploadHelpLabel: string;
  supportedFileTypesLabel?: string;
  supportedFileTypeNames?: string;
  onFilesChange?: (fileList: FileList) => void;
  onFileChange?: (file: File) => void;
  onUploadClicked?: () => void;
  multiple?: boolean;
}) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files === null) {
      return;
    }

    if (multiple && onFilesChange) {
      onFilesChange(files);
    } else if (onFileChange) {
      onFileChange(files[0]);
    }
  };

  const buttonClickHandler = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onUploadClicked) {
      onUploadClicked();
    }

    showFilePicker();
  };

  const showFilePicker = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const SupportedFileTypes = () => {
    if (!supportedFileTypeNames || !supportedFileTypesLabel) {
      return null;
    }

    return (
      <Flex
        justify="center"
        mt={2}
      >
        <Text size="tiny">{supportedFileTypesLabel}:</Text>
        <Text
          ml={0.5}
          size="tiny"
          weight="bold"
        >
          {supportedFileTypeNames}
        </Text>
      </Flex>
    );
  };

  return (
    <Box height="100%">
      <StyledEmptyBox p={0}>
        <Flex
          column
          px={2}
          py={4}
          height="100%"
          justify="center"
          align="center"
        >
          <Flex justify="center">
            <IconVideocam
              size="large"
              css={css`
                color: #dedede;
                font-size: 100px;
              `}
            />
          </Flex>
          <Text
            block
            mt={2}
            mb={4}
            weight="bold"
          >
            {uploadHelpLabel}
          </Text>
          <input
            style={{
              display: "none",
            }}
            multiple={multiple}
            ref={inputRef}
            type="file"
            accept={accept}
            onChange={inputChangeHandler}
          />
          <Button
            onClick={buttonClickHandler}
            icon={IconAdd}
          >
            {buttonLabel}
          </Button>
        </Flex>
      </StyledEmptyBox>
      <SupportedFileTypes />
    </Box>
  );
};

export { UploadTrigger };
