import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

interface Props {
  readOnly?: boolean;
}

const RentalNumberOfRooms = ({ readOnly = false }: Props) => {
  const minRoomValue = 0;
  const maxRoomValue = 20;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  let errorText = "";
  if (errors.rooms) {
    if (String(errors.rooms.message)) {
      errorText = String(errors.rooms.message);
    } else {
      errorText = t("required_field.range", {
        min: minRoomValue,
        max: maxRoomValue,
      });
    }
  }

  return (
    <TextField
      required
      type="number"
      label={t("create.field.rooms.heading")}
      errorText={errorText}
      readOnly={readOnly}
      defaultValue={0}
      {...register("rooms", {
        min: minRoomValue,
        validate: (value: number) =>
          value >= minRoomValue && value <= maxRoomValue,
        valueAsNumber: true,
      })}
    />
  );
};

export { RentalNumberOfRooms };
