import { fetchFromAPI } from "lib/api";

const deleteVideoService = async (
  url: string,
): Promise<{ success: boolean; errorMessage: string | null }> => {
  const request = await fetchFromAPI(url, {
    method: "POST",
  });

  // if everything went ok
  if (request.status === 200) {
    return Promise.resolve({
      success: true,
      errorMessage: null,
    });
  }

  // TODO: sad paths where we resolve with success:false and nice error messages
  if (request.status === 404) {
    return Promise.resolve({
      success: false,
      errorMessage: "there's no matching video on given ad",
    });
  }

  if (request.status === 403) {
    return Promise.resolve({
      success: false,
      errorMessage:
        "not logged in, not ad owner or trying to delete a non-test video from test environment",
    });
  }

  return Promise.reject();
};

export { deleteVideoService };
