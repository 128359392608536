import { useFormContext } from "react-hook-form";
import { TextField } from "@boligportal/juice";
import { t } from "lib/i18n";

const RentalSize = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <TextField
      required
      type="number"
      label={t("create.field.residence.size_m2.heading")}
      errorText={errors.size_m2 && t("required_field.numeric")}
      append={
        <span>
          m<sup>2</sup>
        </span>
      }
      defaultValue={0}
      {...register("size_m2", {
        min: 0,
        validate: (value: number) => value >= 0,
        valueAsNumber: true,
      })}
    />
  );
};

export { RentalSize };
