import { useQuery } from "react-query";
import * as api from "api";
import {
  getContracts,
  getRentableListing,
  getRentableRentOverviewData,
  GetRentableRentOverviewQueryParams,
  getRentables,
  GetRentablesPaginationRequest,
  getWinbackUpsellPlans,
} from "api";
import { GetContractsRequestPayload } from "apps/contracts/components/common/interfaces";
import { MovingReportType } from "apps/moving_reports/enums";
import { ListingData } from "apps/property_owner/my_rentables/views/rentable_detail/views/listings/interfaces/ListingData";
import { TenancyAPI } from "features/rentable_tenancy/TenancyAPI";
import { API } from "lib/api";

export type DetailedStatisticsPayload = {
  rentableId: number;
  startDate?: string;
  endDate?: string;
};

export const rentableKeys = {
  latestDraftContractId: (rentableId?: number) =>
    ["latestDraftContractId", rentableId] as const,
  latestDraftMovingReportId: (rentableId: number) =>
    ["latestDraftMovingReportId", rentableId] as const,
  estates: () => ["estates"] as const,
  contracts: (payload: Partial<GetContractsRequestPayload>) =>
    ["rentableContractsQuery", payload] as const,
  listingActiveUpsell: (rentableId: number | null) =>
    ["rentableListingActiveUpsellQuery", rentableId] as const,
  listingDetailedStatistics: (payload: DetailedStatisticsPayload) =>
    ["rentableListingDetailedStatistics", payload] as const,
  rentableRentOverview: () => ["rentableRentOverview"] as const,
  rentableRentOverviewParams: (
    rentableId: number,
    params: GetRentableRentOverviewQueryParams,
  ) => ["rentableRentOverview", rentableId, params] as const,
  rentableListing: (rentableId: number) =>
    ["rentableListingQuery", rentableId] as const,
  rentableListingProducts: (rentableId: number) =>
    ["rentableListingProductsQuery", rentableId] as const,
  rentableDetails: (rentableId: string | number) =>
    ["rentableDetails", rentableId] as const,
  rentableList: (payload: GetRentablesPaginationRequest) =>
    ["rentableList", payload] as const,
};

export const useLatestDraftContractId = (rentableId?: number) =>
  useQuery(
    rentableKeys.latestDraftContractId(rentableId),
    () => api.getLatestContractDraftId(rentableId),
    {
      enabled: !!rentableId,
      retry: (failureCount, error: any) => {
        if (error?.response?.status === 404) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

export const useLatestDraftMovingReportId = (
  rentableId: number,
  type: MovingReportType,
) =>
  useQuery(rentableKeys.latestDraftMovingReportId(rentableId), () =>
    api.getLatestMovingReportId(rentableId, type),
  );

export const useEstates = () =>
  useQuery(rentableKeys.estates(), api.getEstates);

export const useRentablesQuery = (payload: GetRentablesPaginationRequest) =>
  useQuery(rentableKeys.rentableList(payload), () => getRentables(payload), {
    initialData: {
      data: [],
      all_count: 0,
      filter_count: 0,
    },
    keepPreviousData: true,
  });

export const useRentableQuery = (rentableId: number) =>
  useQuery(
    rentableKeys.rentableDetails(rentableId),
    () => API.fetchRentable(Number(rentableId)),
    {
      select: (data) => data.data,
    },
  );

export const useRentableContractsQuery = (
  payload: Partial<GetContractsRequestPayload>,
) =>
  useQuery(rentableKeys.contracts(payload), () => getContracts(payload), {
    keepPreviousData: true,
  });

export const useRentableListingActiveUpsellQuery = (
  rentableId: number | null,
) => {
  const rentableListingActiveUpsellQuery = useQuery(
    rentableKeys.listingActiveUpsell(rentableId),
    () => TenancyAPI.getActiveUpsellSubscriptions(rentableId!),
    {
      enabled: !!rentableId,
    },
  );

  return rentableListingActiveUpsellQuery;
};

export const useRentableListingDetailedStatistics = (
  payload: DetailedStatisticsPayload,
) => {
  const rentableListingDetailedStatisticsQuery = useQuery(
    rentableKeys.listingDetailedStatistics(payload),
    () =>
      API.getDetailedStatistics(
        payload.rentableId,
        payload.startDate,
        payload.endDate,
      ),
    {
      keepPreviousData: true,
    },
  );

  return rentableListingDetailedStatisticsQuery;
};

export const useRentableRentOverviewQuery = (
  rentableId: number,
  params: GetRentableRentOverviewQueryParams,
) =>
  useQuery(rentableKeys.rentableRentOverviewParams(rentableId, params), () =>
    getRentableRentOverviewData(rentableId, params),
  );

export const useRentableListingProductsQuery = (rentableId: number | null) => {
  const rentableListingProductsQuery = useQuery(
    rentableKeys.rentableListingProducts(rentableId!),
    () => TenancyAPI.getRentableListingProducts(rentableId!),
    {
      enabled: !!rentableId,
      keepPreviousData: true,
      staleTime: 3000,
    },
  );

  return rentableListingProductsQuery;
};

export const useRentableListingQuery = (rentableId: number) => {
  const rentableListingQuery = useQuery<
    {
      address: string;
      category: string;
      listingData: ListingData | null;
    },
    { message: string; response: { status: number } }
  >(
    rentableKeys.rentableListing(rentableId),
    () => getRentableListing(rentableId),
    {
      retry: 0,
    },
  );

  return rentableListingQuery;
};

export const useRentableMovingReportsQuery = (rentableId: number) => {
  const rentableMovingReportsQuery = useQuery(
    ["rentableMovingReportsQuery", rentableId],
    () => TenancyAPI.getRentableMovingReports(rentableId),
  );

  return rentableMovingReportsQuery;
};

export const useGetWinbackUpsellPlans = ({
  planId,
  enabled,
}: {
  planId: number;
  enabled: boolean;
}) =>
  useQuery(
    ["getWinbackUpsellPlans", planId],
    () => getWinbackUpsellPlans(planId),
    {
      enabled: enabled,
    },
  );
