import { useState, useContext, createContext } from "react";

interface DropdownContextProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
}

const DropdownContext = createContext<DropdownContextProps | undefined>(
  undefined,
);

type Props = {
  children: React.ReactNode;
};

export const DropdownProvider = ({ children }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <DropdownContext.Provider value={{ isDropdownOpen, setIsDropdownOpen }}>
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdown = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error("useDropdown must be used within a DropdownProvider");
  }
  return context;
};
