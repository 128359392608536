import { useEffect } from "react";
import {
  Text,
  Box,
  Flex,
  IconCloseCircleOutline,
  EmptyBox,
  IconCheckmarkCircleOutline,
  Spinner,
  styled,
} from "@boligportal/juice";
import { css } from "@emotion/react";
import { useVideoPlaybackFeature } from "features/video_feature/hooks/use_video_playback_feature";

// ************************************************************************************************
// Vimeo Video Playback Feature
// Given a videoId, user can see a video in Vimeo's video player.
// ************************************************************************************************
const VimeoVideoPlayback = ({
  autoplay,
  cover,
  videoId,
  videoHash,
  uploadFinishedHeader,
  uploadFinishedInfo,
  uploadMalformedHeader,
  uploadMalformedInfo,
}: {
  autoplay?: boolean;
  cover?: boolean;
  videoId: number;
  videoHash?: string;
  uploadMalformedHeader: string;
  uploadMalformedInfo: string;
  uploadFinishedHeader: string;
  uploadFinishedInfo: string;
}) => {
  const { videoStatus, checkVideoStatus } = useVideoPlaybackFeature();

  useEffect(() => {
    checkVideoStatus(videoId);
  }, [videoId, checkVideoStatus]);

  return (
    <Box height="100%">
      {videoStatus === "malformed" && (
        <VideoIsMalformed
          uploadMalformedHeader={uploadMalformedHeader}
          uploadMalformedInfo={uploadMalformedInfo}
        />
      )}
      {videoStatus === "idle" && (
        <Flex
          align="center"
          justify="center"
          height="100%"
          p={10}
        >
          <Spinner />
        </Flex>
      )}
      {videoStatus === "playable" && (
        <Box
          bg="tint"
          height="100%"
        >
          <VimeoVideoPlayer
            cover={cover}
            autoplay={autoplay}
            videoId={videoId}
            videoHash={videoHash}
          />
        </Box>
      )}
      {videoStatus === "processing" && (
        <UploadFinished
          uploadFinishedHeader={uploadFinishedHeader}
          uploadFinishedInfo={uploadFinishedInfo}
        />
      )}
    </Box>
  );
};

export { VimeoVideoPlayback };

// ************************************************************************************************
// Sub components that only used by VimeoVideoPlayback Feature
// ************************************************************************************************

const createVideoMarkup = (
  videoId: number,
  videoHash?: string,
  autoplay?: boolean,
  cover?: boolean,
) => {
  const url = videoHash
    ? `https://player.vimeo.com/video/${videoId}?h=${videoHash}&`
    : `https://player.vimeo.com/video/${videoId}?`;
  const videoSettings = `color=ed692c&title=0&byline=0&portrait=0${
    autoplay ? "&muted=1&background=1&autoplay=1" : ""
  }`;
  const videoUrl = `${url}${videoSettings}`;

  const iframeStyle = `position:absolute;height:100%;${
    cover
      ? "top:50%;left:50%;transform:translate(-50%,-50%);width:200%"
      : "top:0;left:0;width:100%;"
  }`;

  const containerStyle = cover
    ? "height:100%;position:relative;"
    : "width:100%;height:100%;position:relative;aspect-ratio:16/9;";

  return {
    __html: `<div style=${containerStyle}><iframe src="${videoUrl}" style=${iframeStyle} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`,
  };
};

const VimeoVideoPlayer = ({
  autoplay,
  cover,
  videoId,
  videoHash,
}: {
  autoplay?: boolean;
  cover?: boolean;
  videoId: number;
  videoHash?: string;
}) => (
  <div
    style={{ height: "100%" }}
    dangerouslySetInnerHTML={createVideoMarkup(
      videoId,
      videoHash,
      autoplay,
      cover,
    )}
  />
);

const StyledEmptyBox = styled(EmptyBox)`
  height: 100%;
`;

const VideoIsMalformed = ({
  uploadMalformedHeader,
  uploadMalformedInfo,
}: {
  uploadMalformedHeader: string;
  uploadMalformedInfo: string;
}) => (
  <StyledEmptyBox>
    <Flex column>
      <Flex
        justify="center"
        height="100%"
      >
        <IconCloseCircleOutline
          css={css`
            font-size: 50px;
          `}
          color="danger"
        />
      </Flex>
      <Box mt={2}>
        <Text weight="bold">{uploadMalformedHeader}</Text>
      </Box>
      <Box>
        <Text>{uploadMalformedInfo}</Text>
      </Box>
    </Flex>
  </StyledEmptyBox>
);

const UploadFinished = ({
  uploadFinishedHeader,
  uploadFinishedInfo,
}: {
  uploadFinishedHeader: string;
  uploadFinishedInfo: string;
}) => (
  <StyledEmptyBox>
    <Flex
      column
      height="100%"
      justify="center"
      align="center"
    >
      <Flex>
        <IconCheckmarkCircleOutline
          css={css`
            font-size: 50px;
          `}
          color="branded"
        />
      </Flex>
      <Box mt={1}>
        <Text weight="bold">{uploadFinishedHeader}</Text>
      </Box>
      <Box>
        <Text>{uploadFinishedInfo}</Text>
      </Box>
    </Flex>
  </StyledEmptyBox>
);
