import { t } from "lib/i18n";
import vest, { enforce, test } from "vest";
import {
  maxRoomValue,
  minRoomValue,
} from "../FormElements/RentalNumberOfRooms";
import { ManualAddressInfo as ManualAddressInfoDK } from "./ManualAddressDenmark";
import { ManualAddressInfo as ManualAddressInfoSE } from "./ManualAddressSweden";

export const validationSuite = vest.create(
  (data: ManualAddressInfoDK | ManualAddressInfoSE) => {
    test("address.street_name", () => {
      enforce(data.address.street_name).isNotEmpty();
    });
    test("address.postal_code", () => {
      enforce(data.address.postal_code).isNotEmpty();
    });
    test("address.street_number", () => {
      enforce(data.address.street_number).isNotEmpty();
    });
    test("address.postal_town", () => {
      enforce(data.address.postal_town).isNotEmpty();
    });
    test("category", t("required_field.selection"), () => {
      enforce(data.category).isNotEmpty();
      enforce(data.category !== t("Choose category")).isTruthy();
    });
    test("size_m2", t("required_field.numeric"), () => {
      enforce(data.size_m2).isNumber();
      enforce(data.size_m2).greaterThanOrEquals(0);
    });
    if (data.category === "rental_room") {
      test("rooms", t("validation.category_room_should_be_1"), () => {
        enforce(data.rooms).equals(1);
      });
    } else {
      test(
        "rooms",
        t("required_field.range", {
          min: minRoomValue,
          max: maxRoomValue,
        }),
        () => {
          enforce(data.rooms).greaterThanOrEquals(minRoomValue);
          enforce(data.rooms).lessThanOrEquals(maxRoomValue);
        },
      );
    }
  },
);
