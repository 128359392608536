import * as React from "react";
import { Box, Flex, Spinner, styled, Text } from "@boligportal/juice";
import { css, keyframes } from "@emotion/react";
import { useListingFavorites } from "api/queries";
import { FavouriteButton } from "components/cards/components/FavoriteButton";
import { Ad } from "components/interfaces/ad";
import { API } from "lib/api";
import { t } from "lib/i18n";
import { getAdImage, getAdImageSrcSet } from "lib/image";
import { FavouriteAdButtonPlacementTracking } from "lib/tracking/events";
import { formatCurrency, formatNumber } from "lib/utils";
import SvgX16px from "media/icons/X16px";
import { App } from "../../../../components/app";
import { Action_mapAdsSelected } from "../../search_result_reducer";

const StyledSpinner = styled.div`
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
`;

export const SelectedAdPopup = ({
  adIds,
  dispatch,
}: {
  adIds: number[];
  dispatch: React.Dispatch<Action_mapAdsSelected>;
}) => {
  const [ads, setAds] = React.useState<Ad[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    API.getAdsForIds(adIds).then((ads) => {
      setAds(ads);
      setIsLoading(false);
    });
  }, [adIds]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <StyledSpinner>
          <Spinner size="medium" />
        </StyledSpinner>
      );
    }

    return ads.map((ad) => (
      <AdCard
        ad={ad}
        key={ad.id}
        disableFavouriteFeature={false}
      />
    ));
  };

  return (
    <StyledWrapper hasMultipleAds={adIds.length > 1}>
      <StyledCloseButton
        onClick={() =>
          dispatch({
            type: "mapAdsSelected",
            payload: [],
          })
        }
      >
        <SvgX16px />
      </StyledCloseButton>

      <StyledList>{renderContent()}</StyledList>
    </StyledWrapper>
  );
};

const StyledCloseButton = styled.button`
  position: absolute;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  box-shadow: none;
  font-size: 16px;
  top: -49px;
  right: 0;
  padding: 0;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  z-index: 1;
  color: ${(props) => props.theme.colorPalette.gray[800]};
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  &:focus {
    background: ${(props) => props.theme.colorPalette.gray[10]};
    outline: none;
  }
`;

const StyledList = styled.div`
  height: inherit;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-radius: inherit;
`;

const SRPAdPopUpEnter = keyframes`
  from {
    transform: translateY(110%);
  }

  to {
    transform: translateY(0);
  }
`;

const StyledWrapper = styled.div<{ hasMultipleAds: boolean }>`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: white;
  margin: auto;
  max-width: 400px;
  height: 108px;
  border-radius: ${(props) => props.theme.borderRadius.lg};
  z-index: 2;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  animation: ${SRPAdPopUpEnter} 0.5s cubic-bezier(0.5, 1.165, 0.51, 1.045);

  ${(props) =>
    props.hasMultipleAds &&
    css`
      height: 162px;
      bottom: 0;
    `}

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    left: 250px;
  }
`;

const StyledAdCardLink = styled.a`
  display: flex;
  color: ${(props) => props.theme.color.text.base};
  text-decoration: none;
  border: none;
  position: relative;

  img {
    flex: 0 0 auto;
    width: 108px;
    height: 108px;
    object-fit: cover;
  }

  & + & {
    border-top: 1px solid ${(props) => props.theme.color.border};
  }

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: ${(props) => props.theme.color.text.base};
    text-decoration: none;
  }

  &:hover {
    background: ${(props) => props.theme.colorPalette.gray[10]};
  }
`;

const StyledAdPopupCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
  padding: 8px;
  min-width: 0;
`;

type MapAdCardProps = {
  ad: Ad;
  disableFavouriteFeature?: boolean;
};

const StyledFavouriteHeartMapCard = styled.div`
  z-index: 1;
  position: absolute;
  bottom: ${(props) => props.theme.unit(1)};
  left: ${(props) => props.theme.unit(1)};
`;

const AdCard = ({ ad, disableFavouriteFeature }: MapAdCardProps) => {
  const { favorites } = useListingFavorites();
  const showCityAreaInfo = ad.city_area && ad.city_area !== "";
  const showCityInfo = ad.city && ad.city !== "";

  return (
    <StyledAdCardLink href={ad.url}>
      {/* We want to do this like on the phone, where we hide the heart entirely, if it is not favorised. */}
      {!disableFavouriteFeature && favorites.includes(ad.id) && (
        <StyledFavouriteHeartMapCard>
          <FavouriteButton
            isFavorited
            adId={ad.id}
            placement={FavouriteAdButtonPlacementTracking.srp_map_view}
          />
        </StyledFavouriteHeartMapCard>
      )}
      <img
        srcSet={getAdImageSrcSet({
          ad,
          width: 328,
          height: 246,
          crop: true,
        })}
        src={getAdImage({
          ad,
          width: 328,
          height: 246,
          crop: true,
        })}
        alt=""
      />
      <StyledAdPopupCard>
        {showCityAreaInfo && (
          <TextOverflowHidden
            weight="bold"
            size="lead"
          >
            {ad.city_area}
          </TextOverflowHidden>
        )}
        {showCityInfo && (
          <Text
            size="body"
            weight="semiBold"
          >
            {ad.city}
          </Text>
        )}
        <Box truncate>{ad.title}</Box>
        <Flex align="end">
          <StyledSize>
            {formatNumber(ad.size_m2, {
              round: true,
            })}
            <small> {t("m2")}</small>
          </StyledSize>
          <StyledRooms>
            {ad.rooms}
            <small> {t("ad_card.short_rooms")}</small>
          </StyledRooms>
          <StyledPrize>
            {formatCurrency(ad.monthly_rent, App.settings.currency, {
              round: true,
            })}
          </StyledPrize>
        </Flex>
      </StyledAdPopupCard>
    </StyledAdCardLink>
  );
};

const StyledSize = styled.div`
  flex: 0 0 auto;
  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 1;
`;

const StyledPrize = styled.div`
  flex: 0 0 auto;
  font-size: 22px;
  font-weight: $font-weight-bold;
  line-height: 1;
`;

const StyledRooms = styled.div`
  flex: 1 1 auto;
  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 1;
  padding: 0 0.5em;
`;

const TextOverflowHidden = styled(Text)`
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
`;
