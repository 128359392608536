import { useFormContext, useWatch } from "react-hook-form";
import { Box, Flex } from "@boligportal/juice";
import { t } from "lib/i18n";
import { CreateRentableForm } from "../types";
import { checkAndUpdateDisabledState } from "../utils/checkAndUpdateDisabledState";
import { getRentalCategoryOptions } from "../utils/getRentalCategoryOptions";
import { shouldShowApartmentInfo } from "../utils/shouldShowAppartmentInfo";
import { RentalApartmentNumber } from "./FormElements/RentalApartmentNumber";
import { RentalCategoryItem } from "./FormElements/RentalCategoryItem";
import { RentalNumberOfRoomsItem } from "./FormElements/RentalNumberOfRooms";
import { RentalSizeItem } from "./FormElements/RentalSizeItem";

type Props = {
  address: string;
};

export const CreateSingleRentable = ({ address }: Props) => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<CreateRentableForm>();

  const categories = getRentalCategoryOptions();

  const fieldName = "rentables.0" as const;

  const watchedCategory = useWatch({
    control,
    name: "rentables.0.category",
  });

  return (
    <>
      <Flex>
        <RentalCategoryItem
          label={t("create.field.category.heading")}
          categories={categories}
          required
          errorText={errors.rentables?.[0]?.category?.message}
          {...register(`${fieldName}.category` as const, {
            onChange: (event) => {
              const category = event.currentTarget.value;
              checkAndUpdateDisabledState(
                fieldName,
                address,
                category,
                setValue,
              );

              if (!shouldShowApartmentInfo(category)) {
                setValue(`${fieldName}.apartmentNumber`, undefined);
              }
            },
          })}
        />

        {shouldShowApartmentInfo(watchedCategory) && (
          <Box pl={2}>
            <RentalApartmentNumber
              errorText={errors.rentables?.[0]?.apartmentNumber?.message}
              required
              {...register(`${fieldName}.apartmentNumber` as const)}
            />
          </Box>
        )}
      </Flex>

      <RentalSizeItem
        label={t("create.field.residence.size_m2.heading")}
        required
        errorText={errors.rentables?.[0]?.sizeM2?.message}
        {...register(`${fieldName}.sizeM2` as const, {
          valueAsNumber: true,
          min: 0,
        })}
      />

      <RentalNumberOfRoomsItem
        label={t("create.field.rooms.heading")}
        required
        errorText={errors.rentables?.[0]?.roomCount?.message}
        {...register(`${fieldName}.roomCount` as const, {
          valueAsNumber: true,
          min: 0,
        })}
      />
    </>
  );
};
