import { useState, useRef, createRef, useEffect } from "react";
import { useErrorNotification } from "apps/customer_service/components/useErrorNotification";
import { Image as ReportImage } from "apps/moving_reports/interfaces/image";
import { App } from "components/app";
import { Image } from "components/interfaces/image";
import Dropzone, { DropzoneFile } from "dropzone";
import Reduce from "image-blob-reduce";
import { t } from "lib/i18n";
import { trackError } from "lib/tracking/errors";
import { getCookie } from "lib/utils";

enum ErrorTypes {
  FILE_NOT_ALLOWED = "file_not_allowed",
  FILE_TOO_LARGE = "file_too_large",
}

export const useDropzoneImageUpload = (
  onImageUploadSuccess: (responsePayload: ReportImage[]) => void,
  url: string = "/api/image/upload",
  compress?: boolean,
  onUploadStart?: () => void,
  singleFile?: boolean,
) => {
  const [isUploading, setIsUploading] = useState(false);
  const dropzoneReady = useRef(false);
  const dropzoneInstanceRef = useRef<Dropzone | null>(null);
  const { addErrorNotification } = useErrorNotification();

  const fakeClickableRef = createRef<HTMLDivElement>();
  const dropzoneRef = createRef<HTMLDivElement>();
  const uploadCallbackRef = useRef<any>();

  useEffect(() => {
    if (dropzoneRef.current === null || fakeClickableRef.current === null) {
      return;
    }

    if (dropzoneReady.current === true) {
      return;
    }

    initializeDropzone(dropzoneRef.current, fakeClickableRef.current);
  }, [dropzoneRef, fakeClickableRef]);

  useEffect(() => {
    uploadCallbackRef.current = onImageUploadSuccess;
  }, [onImageUploadSuccess]);

  const initializeDropzone = (
    dropzoneDiv: HTMLDivElement,
    fakeClickableDiv: HTMLDivElement,
  ) => {
    const dropzoneInstance = new Dropzone(dropzoneDiv, {
      url,
      headers: {
        "X-CSRFToken": getCookie("csrftoken") || "",
      },
      acceptedFiles: App.settings.image_mime_types.join(","),
      previewTemplate: dropzoneDiv.innerHTML,
      parallelUploads: 4,
      clickable: fakeClickableDiv,
      ...(compress && {
        transformFile: async (file, done) => {
          // We should catch the error here as some browsers fail
          // here because of some privacy setting enabled. The error from image-blob-reduce:
          // ERR_GET_IMAGE_DATA
          // Pica: cannot use getImageData on canvas, make sure fingerprinting protection isn't enabled
          try {
            const reduce = Reduce();
            const newFile = await reduce.toBlob(file, {
              max: 2560,
            });

            done(newFile);
          } catch (error) {
            trackError(error);
            done(file);
          }
        },
      }),
      thumbnailMethod: "crop",
      thumbnailWidth: 240,
      thumbnailHeight: 240,
      ...(singleFile && {
        maxFiles: 1,
      }),
      addedfile: handleAddFiles,
      success: handleSuccess as any,
      error: handleError,
      queuecomplete: handleQueueComplete as any,
    });

    dropzoneInstanceRef.current = dropzoneInstance;
    dropzoneReady.current = true;
  };

  const uploadedImages = useRef<ReportImage[]>([]);

  const handleAddFiles = () => {
    uploadedImages.current = [];

    if (onUploadStart) {
      onUploadStart();
    }

    setIsUploading(true);
  };

  const handleSuccess = (_: DropzoneFile, response: Image) => {
    uploadedImages.current.push({
      id: response.image_id!,
      url: response.url,
    });
  };

  const handleError = (_: DropzoneFile, error: any) => {
    if (error?.error?.code === ErrorTypes.FILE_NOT_ALLOWED) {
      addErrorNotification(t("errors.image_upload.invalid_type"), {
        autoDismiss: true,
      });
    } else if (error?.error?.code === ErrorTypes.FILE_TOO_LARGE) {
      addErrorNotification(t("errors.image_upload.too_large"), {
        autoDismiss: true,
      });
    } else {
      addErrorNotification(t("errors.image_upload.other"), {
        autoDismiss: true,
      });
    }

    trackError(error.error ? error.error.message : error);
  };

  const handleQueueComplete = () => {
    if (dropzoneInstanceRef.current === null) {
      return;
    }

    uploadCallbackRef.current(uploadedImages.current);
    setIsUploading(false);
  };

  const resetDropzone = () => {
    if (dropzoneInstanceRef.current === null) {
      return;
    }

    dropzoneInstanceRef.current.removeAllFiles();
  };

  return {
    resetDropzone,
    fakeClickableRef,
    dropzoneRef,
    isUploading,
  };
};
