import { useQuery } from "react-query";
import { http } from "@boligportal/juice";

type GrowthbookResponse = {
  experiment_result: boolean | null;
};

// When introducing a new experiment key, you should add it to the union type EXPERIMENT.
type EXPERIMENT = "use-new-inbox";

const growthbookQueryKeys = {
  splitTest: (experiment_key: EXPERIMENT) =>
    ["growthbookQuery", experiment_key] as const,
};

export const useGrowthbookQuery = (experiment_key: EXPERIMENT) =>
  useQuery(growthbookQueryKeys.splitTest(experiment_key), () =>
    http.post<GrowthbookResponse>("/growthbook/evaluate_experiment/", {
      experiment_key,
    }),
  );
