import { Box, styled } from "@boligportal/juice";
import { AdCard } from "components/cards/ad_card";
import { Ad } from "components/interfaces/ad";
import {
  FavouriteAdButtonPlacementTracking,
  TrackingEvent,
} from "lib/tracking/events";

type GridProps = {
  children: React.ReactNode;
};

export const AdGrid = ({ children }: GridProps) => (
  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    {children}
  </div>
);

const AdCardEntry = styled.div`
  // We only want to display 8 ads on mobile
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    &:nth-child(n + 9) {
      display: none;
    }
  }
`;

export const PopularAds = ({ listings }: { listings: Ad[] }) => (
  <AdGrid>
    {listings.map((listing) => (
      <AdCardEntry key={listing.id}>
        <Box>
          <AdCard
            onClick={TrackingEvent.clickHomePagePopularAd}
            ad={listing}
            placement={FavouriteAdButtonPlacementTracking.home_page}
          />
        </Box>
      </AdCardEntry>
    ))}
  </AdGrid>
);
