import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { getCookiesConsent } from "lib/cookies";

export type EventParameters = Record<string, string | number | Array<unknown>>;
type GA4Event = (name: string, parameters?: EventParameters) => void;

export class Tracking {
  public static trackMatomo = ({
    category,
    action,
    name,
    value,
  }: {
    category: string;
    action: string;
    name: string;
    value?: string;
  }) => {
    if (App.settings.market !== Market.BOSTADSPORTAL_SE) {
      return;
    }

    const dataLayer = window._paq || [];

    const event = ["trackEvent", category, action, name];

    if (typeof value !== "undefined") {
      event.push(value);
    }

    if (getCookiesConsent("cookie_cat_statistic")) {
      dataLayer.push(event);
    }
  };

  public static trackGA4Event: GA4Event = (eventName, parameters = {}) => {
    if (App.settings.market === Market.BOSTADSPORTAL_SE) {
      return;
    }
    const dataLayer = window.dataLayer || [];
    const event = {
      event: "track-event-ga4",
      eventName,
      ...parameters,
    };

    if (getCookiesConsent("cookie_cat_statistic")) {
      dataLayer.push(event);
    }
  };

  public static trackEvent(metadata: {
    category: string;
    action: string;
    label: string;
    isInteraction: boolean;
    value?: string;
    extraData?: object;
  }) {
    const dataLayer = window.dataLayer || [];
    let event = {
      event: "track-event",
      eventCategory: metadata.category,
      eventAction: metadata.action,
      eventLabel: metadata.label,
      // value must be provided to Tag Manager, even if undefined,
      // because if not, it will use the last value given
      eventValue: metadata.value,
      eventNoninteraction: !metadata.isInteraction,
    };
    if (metadata.extraData) {
      // Merge in extra data
      event = {
        ...event,
        ...metadata.extraData,
      };
    }

    if (getCookiesConsent("cookie_cat_statistic")) {
      dataLayer.push(event);
    }
  }

  public static trackVirtualPageview(url: string, title: string = "") {
    if (App.settings.market === Market.BOSTADSPORTAL_SE) {
      return;
    }
    const dataLayer = window.dataLayer || [];
    const uetq = window.uetq || [];
    const urlWithSlashPrefix = url.startsWith("/") ? url : `/${url}`;

    const pageLocation = `${window.location.protocol}//${window.location.hostname}${urlWithSlashPrefix}`;

    if (getCookiesConsent("cookie_cat_statistic")) {
      dataLayer.push({
        event: "track-vp",
        vpPath: url,
        vpTitle: title,
        vpPageLocation: pageLocation, // Used in GA4
      });
      uetq.push("event", "page_view", {
        page_path: url,
      });
    }
  }

  public static fireRemarketingTag(google_tag_params: object) {
    if (App.settings.market === Market.BOSTADSPORTAL_SE) {
      return;
    }
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "fireRemarketingTag",
      google_tag_params,
    });
  }

  public static getGoogleAnalyticsClientId(): string | null {
    if (
      !window.ga ||
      !window.ga.getAll ||
      App.settings.market === Market.BOSTADSPORTAL_SE
    ) {
      // No Google Analytics present
      return null;
    }
    const trackers = window.ga.getAll();
    if (trackers.length) {
      return trackers[0].get("clientId");
    } else {
      // Tracker maybe wasn't initialized
      return null;
    }
  }
}
