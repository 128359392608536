import { QueryClient } from "react-query";
import * as api from "api";
import { PropertyData } from "api/types";
import { RentableCreationCandidate } from "features/eco_system/types";
import { formatAddressWithExtraText } from "features/eco_system/utils/formatAddressWithExtraText";
import { oisHousingIdToRentalCategory } from "features/eco_system/utils/oisHousingIdToRentalCategory";
import { AdCategories } from "lib/enums";
import { getPropertyInfo } from "../../queries/usePropertyInfo";
import { AddressSearchCandidate } from "../AddressSearch/AddressSearchItemRenderer";

export const getRentableCreationCandidatesSE = (
  item: AddressSearchCandidate,
): RentableCreationCandidate[] => [
  {
    address: formatAddressWithExtraText(item.address, item.extraText),
    roomCount: undefined,
    sizeM2: undefined,
    apartmentNumber: undefined,
    shouldCreate: true,
    disabled: false,
    estate: null,
  },
];

export const getRentableCreationCandidatesDK = async (
  item: AddressSearchCandidate,
  accessAddress: string,
  queryClient: QueryClient,
): Promise<RentableCreationCandidate[]> => {
  const data = await getPropertyInfo(queryClient, item.accessAddressId);
  let propertyInfo: PropertyData[] = data.results;
  // If user has only selected one address we property info for the other addresses
  if (item.type === "address") {
    propertyInfo = data.results.filter(
      (result) => item.id === result.address.place_id,
    );

    // In some cases we don't get any property info when looking up a property by its accessAddressId. For those cases we want to just let the user fill in the data.
    // This will never happen for item.type === "bulk_create" as we use propertyInfo to construct those items.
    if (propertyInfo.length === 0) {
      const rentable: RentableCreationCandidate = {
        address: item.address,
        disabled: false,
        shouldCreate: true,
        estate: null,
        apartmentNumber: undefined,
      };

      return [rentable];
    }
  }

  const rentables = await Promise.all(
    propertyInfo.map(async ({ address, property_info }) => {
      const { formatted_address } = address;
      const { housing_type_id, rooms_num, housing_size } = property_info;
      const category = oisHousingIdToRentalCategory(housing_type_id);

      // It's possible to have multiple rentables on the same address if the category is ROOM.
      // Therefore if we don't know the category, just check if we can create it as an apartment.
      // If we can't, we disable the field since it most likely means we have another rentable on that address.
      const { can_create_rentable } = await api
        .checkCanCreateRentable(
          formatted_address,
          category || AdCategories.APARTMENT,
        )
        .catch(() => ({
          can_create_rentable: false,
        }));

      const rentable: RentableCreationCandidate = {
        address: formatted_address,
        roomCount: rooms_num || 0,
        sizeM2: housing_size || 0,
        shouldCreate: true,
        disabled: !can_create_rentable,
        // We only want to set the estate if there are multiple properties on the same address
        estate: propertyInfo.length > 1 ? accessAddress : null,
      };

      // Setting category on the rentable object, even if it's undefined, causes RHF to set the value of
      // the SelectField to undefined, instead of the default value "Vælg type"
      if (category) {
        rentable.category = category;
      }

      return rentable;
    }),
  );

  return rentables;
};
