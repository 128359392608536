/* eslint-disable react/jsx-max-depth */
import * as React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BaseStyles,
  boligportalTheme,
  NotificationsProvider,
  ThemeProvider,
} from "@boligportal/juice";
import { DevTools } from "components/DevTools";
import { Backdrop } from "components/dialog/dialog";
import { Settings } from "components/interfaces/settings";
import { User } from "components/interfaces/user";
import { modalActions } from "components/modal_controller";
import { AuthenticatedUserPollingProvider } from "features/polling/AuthenticatedUserPollingProvider";
import { LeadsProvider } from "leads/LeadsProvider";
import { AppActions, AppActionsProvider } from "./AppActionsProvider";
import { DropdownProvider } from "./DropDownProvider";
import { MarketSettingsProvider } from "./MarketSettingsProvider";
import { ModalProvider } from "./ModalProvider";
import { ReactQueryProvider } from "./ReactQueryProvider";
import { SettingsProvider } from "./SettingsProvider";
import { TranslationsProvider } from "./TranslationsProvider";
import { UserProvider } from "./UserProvider";

const ModalRenderer = () => {
  const { modal } = React.useContext(modalActions);
  return <>{modal}</>;
};

type Props = {
  children: React.ReactNode;
  actions: AppActions;
  settings: Settings;
  user: User | null;
};

export const Providers = ({ children, actions, settings, user }: Props) => (
  <ReactQueryProvider>
    <DropdownProvider>
      <ThemeProvider theme={boligportalTheme}>
        <BaseStyles />
        <SettingsProvider settings={settings}>
          <TranslationsProvider language={settings.language}>
            <ModalProvider>
              <AppActionsProvider actions={actions}>
                <MarketSettingsProvider market={settings.market}>
                  <UserProvider user={user}>
                    <NotificationsProvider>
                      <AuthenticatedUserPollingProvider>
                        <LeadsProvider
                          market={settings.market}
                          environment={settings.env}
                        >
                          <DevTools />
                          <ReactQueryDevtools
                            position="bottom-right"
                            initialIsOpen={false}
                          />
                          {children}
                          <ModalRenderer />
                          <Backdrop />
                        </LeadsProvider>
                      </AuthenticatedUserPollingProvider>
                    </NotificationsProvider>
                  </UserProvider>
                </MarketSettingsProvider>
              </AppActionsProvider>
            </ModalProvider>
          </TranslationsProvider>
        </SettingsProvider>
      </ThemeProvider>
    </DropdownProvider>
  </ReactQueryProvider>
);
