import { useState } from "react";
import { Flex, styled } from "@boligportal/juice";
import { withErrorBoundary } from "@sentry/react";
import { useListingFavorites } from "api/queries";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { AdUtils } from "lib/ad";
import { FavouriteAdButtonPlacementTracking } from "lib/tracking/events";
import { FreeContactBadge, UnavailableBadge } from "../components/Badges";
import { FavouriteButton } from "../components/FavoriteButton";
import { Buttons } from "./Buttons";
import { DetailedPrice } from "./DetailedPrice";
import { Headline } from "./Headline";
import { PromotedButton } from "./PromotedButton";
import { ShortPrice } from "./ShortPrice";
import { Slider } from "./Slider";

const StyledContainer = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-decoration: none;
  z-index: 2;
  color: ${(props) => props.theme.color.text.muted};
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: ${(props) => props.theme.borderRadius.md};

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    border-radius: ${(props) => props.theme.borderRadius.sm};
  }

  :hover {
    text-decoration: none;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const StyledFavoriteContainer = styled.div<{
  isFavorited: boolean;
  isHovered: boolean;
}>`
  z-index: 1;
  position: absolute;
  top: ${(props) => props.theme.unit(2)};
  right: ${(props) => props.theme.unit(2)};

  opacity: 1;

  @media (min-width: ${(props) =>
      props.theme.breakpoints.sm}) and (any-hover: hover) {
    opacity: ${(props) => (props.isHovered || props.isFavorited ? 1 : 0)};
    transition: opacity 0.5s;
  }
`;

type Props = {
  ad: Ad;
  linkTarget?: string;
  placement: FavouriteAdButtonPlacementTracking;
  isFavouriteDisabled?: boolean;
  isExtendedDetails?: boolean;
  isPromoted?: boolean;
  morePromotedAdsButton?: boolean;
  onClick?: (id: Ad["id"]) => void;
  onFetchNewPromotedAds?: () => void;
};

const AdCardWrapped = ({
  ad,
  linkTarget = "_self",
  placement,
  isFavouriteDisabled = false,
  isExtendedDetails = false,
  isPromoted = false,
  morePromotedAdsButton,
  onClick,
  onFetchNewPromotedAds,
}: Props) => {
  const { id, url, is_contacted } = ad;

  const { favorites } = useListingFavorites();

  const [isHovered, setIsHovered] = useState(false);

  const isFreeContact = AdUtils.isFreeContact(ad);
  const isUnavailable = AdUtils.isUnavailable(ad);

  const isFavorited = favorites.includes(id);

  const getUrl = () => {
    if (!App.settings.isEmbedded) {
      return url;
    }
    return `${App.settings.apiBaseUrl}${url}`;
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const renderBadge = () => {
    if (isUnavailable) {
      return <UnavailableBadge />;
    }

    if (isFreeContact) {
      return <FreeContactBadge />;
    }

    return null;
  };

  const renderFavoriteButton = () => {
    if (isFavouriteDisabled) {
      return null;
    }

    return (
      <StyledFavoriteContainer
        isHovered={isHovered}
        isFavorited={isFavorited}
      >
        <FavouriteButton
          adId={id}
          isFavorited={isFavorited}
          placement={placement}
        />
      </StyledFavoriteContainer>
    );
  };

  return (
    <>
      <StyledContainer
        className="AdCardSrp__Link"
        onClick={handleOnClick}
        href={getUrl()}
        target={linkTarget}
      >
        <SliderContainer
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Slider
            ad={ad}
            isDisabled={isUnavailable}
            actionsLayer={
              <>
                {renderFavoriteButton()}
                {renderBadge()}
              </>
            }
          />
        </SliderContainer>

        <Flex
          column
          flexGrow
          width="100%"
        >
          <Headline ad={ad} />

          {isExtendedDetails ? (
            <>
              <DetailedPrice ad={ad} />

              <Buttons
                ad={ad}
                isContacted={is_contacted}
                isUnavailable={isUnavailable}
                isFreeContact={isFreeContact}
              />
            </>
          ) : (
            <ShortPrice
              ad={ad}
              isPromoted={isPromoted}
            />
          )}
        </Flex>
      </StyledContainer>

      <PromotedButton
        isVisible={morePromotedAdsButton}
        onClick={onFetchNewPromotedAds}
      />
    </>
  );
};

export const AdCard = withErrorBoundary(AdCardWrapped, {});
