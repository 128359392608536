import { useCallback, useEffect } from "react";
import {
  Alert,
  Flex,
  IconAlertCircleOutline,
  styled,
  Spinner,
  Text,
  EmptyBox,
  Box,
} from "@boligportal/juice";
import { UploadTrigger } from "components/upload_trigger/upload_trigger";
import { useVideoUploadFeature } from "features/video_feature/hooks/use_video_upload_feature";

const StyledEmptyBox = styled(EmptyBox)`
  height: 100%;
`;

const Wrapper = styled(Box)`
  position: relative;
`;

const AlertWrapper = styled(Box)`
  position: absolute;
  width: 100%;
`;

type VideoUploaderProps = {
  url: string;
  onUploadFinished: (newVideoId: number) => void;
  onUploadClicked?: () => void;
  buttonLabel: string;
  uploadHelpLabel: string;
  supportedFileTypesLabel?: string;
  supportedFileTypeNames?: string;
  uploadProgressLabel: string;
};

// ************************************************************************************************
// Video Uploader Feature
// Given an adId, user can upload a video.
// The new video id can then be used by the implementing view
// ************************************************************************************************
const VideoUploader = ({
  url,
  onUploadFinished,
  onUploadClicked,
  buttonLabel,
  uploadHelpLabel,
  uploadProgressLabel,
  supportedFileTypeNames,
  supportedFileTypesLabel,
}: VideoUploaderProps) => {
  const { uploadAndSave, progress, videoUploadStatus, errorMessage, videoId } =
    useVideoUploadFeature();

  const uploadTriggerFileChangeHandler = useCallback(
    (file: File) => {
      uploadAndSave(url, file);
    },
    [url, uploadAndSave],
  );

  useEffect(() => {
    if (videoUploadStatus === "finished" && videoId) {
      onUploadFinished(videoId);
    }
  }, [videoUploadStatus, onUploadFinished, videoId]);

  return (
    <Wrapper height="100%">
      {videoUploadStatus === "failed" && (
        <UploadFailed errorMessage={errorMessage || "Something went wrong"} />
      )}

      {(videoUploadStatus === "idle" || videoUploadStatus === "failed") && (
        <UploadTrigger
          accept="video/*"
          buttonLabel={buttonLabel}
          uploadHelpLabel={uploadHelpLabel}
          supportedFileTypesLabel={supportedFileTypesLabel}
          supportedFileTypeNames={supportedFileTypeNames}
          onFileChange={uploadTriggerFileChangeHandler}
          onUploadClicked={onUploadClicked}
        />
      )}
      {videoUploadStatus === "busy" && (
        <UploadProgressBar
          progress={progress}
          uploadProgressLabel={uploadProgressLabel}
        />
      )}
    </Wrapper>
  );
};

export { VideoUploader };

// ************************************************************************************************
// Sub components that only used by VideoUploader Feature
// ************************************************************************************************
const UploadFailed = ({ errorMessage }: { errorMessage: string }) => (
  <AlertWrapper>
    <Alert type="danger">
      <Flex gap={1}>
        <IconAlertCircleOutline
          color="danger"
          size="medium"
        />
        <div>{errorMessage}</div>
      </Flex>
    </Alert>
  </AlertWrapper>
);

// ------------------------------------------------------------------------------------------------
// Progress Bar -> Could be a juice component
// ------------------------------------------------------------------------------------------------
const StyledProgressBarWrapper = styled.div`
  display: flex;
  height: 10px;
  width: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colorPalette.gray[50]};
  overflow: hidden;
`;

const StyledProgressBar = styled.div<{ progress: number }>`
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colorPalette.primary[500]};
  transform: translateX(-${(props) => 100 - props.progress}%);
  transition: transform 0.5s ease;
`;

const ProgressBar = ({ progress }: { progress: number }) => (
  <StyledProgressBarWrapper>
    <StyledProgressBar progress={progress} />
  </StyledProgressBarWrapper>
);

const UploadProgressBar = ({
  progress,
  uploadProgressLabel,
}: {
  progress: number;
  uploadProgressLabel: string;
}) => (
  <StyledEmptyBox>
    <Flex
      column
      align="center"
    >
      <Spinner size="large" />
      <Text
        my={2}
        weight="bold"
      >{`${uploadProgressLabel} - ${progress} %`}</Text>
      <ProgressBar progress={progress} />
    </Flex>
  </StyledEmptyBox>
);
