import { http } from "@boligportal/juice";
import {
  GetContractsRequestPayload,
  GetContractsResponsePayload,
} from "apps/contracts/components/common/interfaces";
import { ContractListItem } from "apps/contracts/components/common/store/contracts";
import { MovingReportType } from "apps/moving_reports/enums";
import { MyRentablesListDataItem } from "apps/property_owner/my_rentables/interfaces/MyRentablesListDataItem";
import { PaymentStat } from "apps/property_owner/my_rentables/views/rentable_detail/views/rent_overview/components/PaymentSummary";
import { RentableRecordPresentor } from "apps/property_owner/my_rentables/views/rentables_list/presentation/RentableRecordPresenter";
import { GetRentOverviewResponse } from "apps/rent/types/GetRentOverviewResponse";
import { GetRentRecordsResponse } from "apps/rent/types/GetRentRecordsResonse";
import { RentRecord } from "apps/rent/types/RentRecord";
import { PowerBiDashboard } from "apps/rental_data/types/PowerBiDashboard";
import {
  Branding,
  RentCollectionNotificationSettings,
} from "apps/user_settings/interfaces";
import { Tenancy } from "business/domain/tenancy/Tenancy";
import { TenancyPaymentEventItem } from "business/domain/tenancy/TenancyPaymentEventItem";
import { TenancyPaymentInfo } from "business/domain/tenancy/TenancyPaymentInfo";
import { TenancyPaymentStatus } from "business/domain/tenancy/TenancyPaymentStatus";
import { TenancyPaymentTransaction } from "business/domain/tenancy/TenancyPaymentTransaction";
import { TenancyPaymentType } from "business/domain/tenancy/TenancyPaymentType";
import { TenancyRent } from "business/domain/tenancy/TenancyRent";
import { TenancyRentEntity } from "business/domain/tenancy/TenancyRentEntity";
import { App } from "components/app";
import { Ad } from "components/interfaces/ad";
import { Plan } from "components/interfaces/plan";
import { Subscription } from "components/interfaces/subscription";
import { Market } from "components/markets/market_settings";
import { ManualAddress } from "features/address/common/interfaces/ManualAddress";
import {
  NudgeName,
  NudgePlacement,
} from "features/mark_as_reserved_or_rented_out/contract_nudge/useContractNudgeDialog";
import { TenancySetupRentPayload } from "features/rentable_tenancy/setup_rent/types/TenancySetupRentPayload";
import {
  TenancyBasePayload,
  TenancyDiffResponse,
  TenancyTenantPayload,
} from "features/tenancy/useTenancyDiffUpdater/types";
import { fetchFromAPI } from "lib/api";
import { OrderType, FavoriteCardStatus } from "lib/enums";
import { addBreadcrumb } from "lib/tracking/errors";
import { DomutechSource } from "../leads/Domutech/DomuTechDialog";
import {
  AutocompleteItem,
  CreateRentablePayload,
  CreateRentableResponse,
  LastCanceledSubscriptionResponse,
  PropertyDataResponse,
  TenanciesListPayload,
  TenanciesListResponse,
} from "./types";

type GetFavoritesListRequestPayload = {
  offset: number | null;
  limit: number | null;
  status: FavoriteCardStatus | null;
  sorting: OrderType | null;
};

export type LocationAutoCompleteResponse = {
  results: {
    url: string;
    name: string;
  }[];
};

export type GetRentRecordsQueryParams = {
  search: string;
  offset: number;
  limit: number;
  sorting: keyof RentRecord;
  descending: boolean;
  category: "arrears" | "upcoming_month" | "current_month" | "all";
};

interface GetRentablesPaginationResponse {
  all_count: number;
  filter_count: number;
  data: Array<MyRentablesListDataItem>;
}

export type MerchantProvider = {
  Name: string;
  SubscriptionProviderId: string;
  Status: string;
};

type MerchantProvidersResponse = {
  Id: string;
  SubscriptionProviders: MerchantProvider[];
};

interface PaginatedTenancyRentResponse extends TenancyRent {
  all_count: number;
}

export type PaginatedTenancyRent = {
  offset?: number;
  limit?: number;
  numMonths?: number;
  sorting?: { column: string; descending: boolean };
};

export interface GetRentablesPaginationRequest {
  limit: number;
  start_index: number;
  filters: {
    search?: string;
    sorting?: keyof RentableRecordPresentor;
    descending?: boolean;
  };
}

export type GetFavoritesListResponsePayload = {
  ads: Ad[];
  all_count: number;
};

export type GetFavoritesIdsResponsePayload = {
  favorite_ad_ids: number[];
};

type GetTopFavoritesListRequestPayload = {
  city: string;
};

export type GetRentableRentOverviewQueryParams = {
  num_months: number;
  sorting: {
    column: string;
    descending: boolean;
  };
  offset: number;
  limit: number;
};

type GetRentableRentOverviewResponse = {
  hasPayments: boolean;
  all_count: number;
  address: string;
  tenancyRentOverviewItems: RentRecord[];
  arrears: PaymentStat;
  paidTotal: PaymentStat;
  paidLastTwelveMonths: PaymentStat;
};

export type GetPaymentDetailsResponse = {
  base_payments: TenancyRentEntity[] | null;
  event_log: TenancyPaymentEventItem[];
  note: string;
  one_time_payments: TenancyRentEntity[] | null;
  payment_due_date: string;
  payment_id: number;
  payment_state: TenancyPaymentStatus;
  payment_type: TenancyPaymentType;
  paymentInfo: TenancyPaymentInfo;
  rent_regulation: TenancyRentEntity[] | null;
  tenancy: Tenancy;
  transactions: TenancyPaymentTransaction[];
  is_within_edit_deadline: boolean;
};

export const getUnseenThreadCount = () =>
  http.get<{ unseen_thread_count?: number }>(
    "/messaging/stats/unseen_thread_count",
  );

export const updateBranding = (branding: Partial<Branding>) =>
  http.patch<Branding>("/user/profile/branding", branding);

export const createImageFromS3Key = (s3_key: string) =>
  http.post<{ id: number; url: string }>("/image/create-from-s3-key", {
    s3_key,
  });

export const updateRentCollectionSettings = (
  settings: Partial<RentCollectionNotificationSettings>,
) =>
  http.post<RentCollectionNotificationSettings>(
    "/tenancy/notifications-options",
    settings,
  );

export const getHedvigContractStatusPageIframe = (tenant_token: string) =>
  http.post<{ iframe_url: string }>("/leads/hedvig/contract", {
    tenant_token,
  });

export const getHedvigSubscriptionStatusPageIframe = () =>
  http.get<{ iframe_url: string }>("/leads/hedvig/on_cancel_subscription");

export const getHedvigInboxThreadPageIframe = (ad_id: number) =>
  http.post<{ iframe_url: string }>("/leads/hedvig/on_chosen_as_tenant", {
    ad_id,
  });

export const getHedvigAdDetailPageIframe = (ad_id: number) =>
  http.post<{ iframe_url: string }>("/leads/hedvig/ad-detail-page", {
    ad_id,
  });

export const saveHedvigContractLeadData = (payload: {
  name: string;
  email: string;
  contract_id: number;
  tenant_id: number;
}) => http.post("/leads/bi/hedvig/contract", payload);

export const saveHedvigSubscriptionLeadData = (payload: {
  name: string;
  email: string;
  subscription_id: number;
}) => http.post("/leads/bi/hedvig/on_cancel_subscription", payload);

export const saveHedvigInboxLeadData = (payload: {
  name: string;
  email: string;
  thread_id: number;
}) => http.post("/leads/bi/hedvig/on_chosen_as_tenant", payload);

export const saveHedvigAdDetailPageLeadData = (payload: { ad_id: number }) =>
  http.post("/leads/bi/hedvig/ad-detail-page", payload);

export const getLatestContractDraftId = (rentable_id?: number) =>
  http.get<{ latest_draft_contract_id: number }>(
    `/rentable/${rentable_id}/contracts/latest-draft-contract`,
  );

export const trackContractNudgeAfterReservingOrRentingOutAd = (data: {
  placement: NudgePlacement;
  name: NudgeName;
  contract_id: number;
}) => http.post("/contracts/contract/bi_nudge_tracking/", data);

export const getFailedPaymentNotificationsCount = () =>
  http.get<{ paymentNotificationsCount?: number }>(
    "tenancy/failed-payment-notifications-count",
  );

export const getLatestMovingReportId = (
  rentable_id: number,
  type: MovingReportType,
) =>
  http.get<{ latest_draft_moving_report_id: number }>(
    `/rentable/${rentable_id}/moving-reports/latest-draft-moving-report/?type=${type}`,
  );

export const retrieveAndUpdateEnergyLabel = (rentable_id: number) =>
  http.get<{
    label_id: null | string;
    rating: null | string;
  }>(`rentable/${rentable_id}/retrieve-and-update-energy-rating/`);

export const saveDomutechLeadData = (payload: {
  name: string;
  phone_number: string;
  rentable_id: number;
  source: DomutechSource;
  email: string;
}) => http.post("/leads/domutech/", payload);

export const saveBetalingsserviceSettingsFormData = (payload: {
  bs_number: string | null;
  fi_number: string | null;
  debtor_group_number: number | null;
}) =>
  http.post("/tenancy/save-betalingsservice-credentials", {
    bs_number: payload.bs_number?.trim(),
    fi_number: payload.fi_number?.trim(),
    debtor_group_number: payload.debtor_group_number,
  });

export const getAddressAutocompleteDK = async (
  searchValue: string,
  signal?: AbortSignal,
) => {
  const response = await fetch(
    `https://address.boligportal.dk/autocomplete/dk/${searchValue}`,
    { signal },
  );
  const data = (await response.json()) as AutocompleteItem[];

  return data;
};
export const getAddressAutocompleteSE = async (
  searchValue: string,
  containerId: string,
  signal?: AbortSignal,
) => {
  const response = await fetch(
    `https://address.boligportal.dk/autocomplete/se/${searchValue}${
      containerId !== "" ? `?Container=${containerId}` : ""
    }`,
    { signal },
  );
  const data = (await response.json()) as AutocompleteItem[];

  return data;
};

export const getPropertyInfo = async (accessAddressId: string) => {
  const response = await fetch(
    `https://address.boligportal.dk/info/ois/property_info/${accessAddressId}`,
  );
  const data = (await response.json()) as PropertyDataResponse;

  return data;
};

export const checkCanCreateRentable = async (
  address: string | ManualAddress,
  category: string,
) =>
  await http.post<{ can_create_rentable: boolean }>(
    "/rentable/check-can-create-rentable/",
    {
      address,
      category,
    },
  );

export const createRentable = (data: CreateRentablePayload) =>
  http.post<CreateRentableResponse>("/rentable/", data);

export const getEstates = () =>
  http.get<{ estates: string[] }>("/rentable/estates/");

export const markAdAsFavourite = ({
  adId,
  markAsFavourite,
}: {
  adId: number;
  markAsFavourite: boolean;
}) =>
  http.put<{ is_favorite: boolean }>(`/listing/${adId}/favorise/`, {
    mark_as_favourite: markAsFavourite,
  });

export const getFavouriteAdsList = async (
  payload: Partial<GetFavoritesListRequestPayload>,
) => {
  const res = await fetchFromAPI("/api/listing/favorites/", {
    method: "POST",
    body: JSON.stringify(payload),
  });
  const data = await res.json();
  return data;
};

export const getTopFavouriteAdsList = async (
  payload: GetTopFavoritesListRequestPayload,
) => {
  const res = await fetchFromAPI("/api/listing/top-favorite-ads/", {
    method: "POST",
    body: JSON.stringify(payload),
  });
  const data = await res.json();
  return data;
};

export const getFavoriteListing = () =>
  http
    .get<GetFavoritesIdsResponsePayload>("/listing/favorite-ad-ids/")
    .then((response) => {
      if (!response.favorite_ad_ids) {
        addBreadcrumb(
          `No favorite_ad_ids in response: ${JSON.stringify(response)}`,
        );
      }

      return response;
    });

export const getSubscription = () =>
  http.get<LastCanceledSubscriptionResponse>(
    "/subscriptions/last-cancelled-subscription/",
  );

export const getTenansiesList = (payload: TenanciesListPayload) =>
  http.post<TenanciesListResponse>("/tenancy/tenancies/", payload);

export const getTenancyMovingReport = (tenancyId: number) =>
  http.get(`/moving_reports/tenancy/${tenancyId}/`);

export const getTenancyContracts = (tenancyId: number) =>
  http.get<{ entries: ContractListItem[] }>(
    `${
      App.settings.market === Market.BOLIGPORTAL_DK
        ? "dk_contracts"
        : "se_contracts"
    }/tenancy/${tenancyId}/contracts`,
  );

export const getRentableTenancies = (rentableId: number) =>
  http.get<{ tenancies: Tenancy[] }>(`/tenancy/${rentableId}`);

export const getContracts = (payload: Partial<GetContractsRequestPayload>) =>
  http.post<GetContractsResponsePayload>(
    `/rentable/${payload.rentable_id}/contracts-page`,
    payload,
  );

export const getTenancy = (tenancyId: number) =>
  http.get<Tenancy>(`/tenancy/${tenancyId}/get`);

export const getExportableYearsOptions = () =>
  http.get<{ years: number[] }>("/tenancy/payments/excel_exportable_years/");

export const getContractTenancyDiff = (contractId: number) =>
  http.get<TenancyDiffResponse>(
    `/tenancy/get-tenancy-diff/contract/${contractId}`,
  );

export const getMovingReportTenancyDiff = (movingReportId: number) =>
  http.get<TenancyDiffResponse>(
    `/tenancy/get-tenancy-diff/moving-report/${movingReportId}`,
  );

export const getTenancyRent = (
  tenancyId: number,
  pagination: PaginatedTenancyRent,
) =>
  http.post<PaginatedTenancyRentResponse>(`/tenancy/${tenancyId}/payments/`, {
    offset: pagination.offset,
    limit: pagination.limit,
    num_months: pagination.numMonths,
    sorting: pagination.sorting,
  });

export const updateTenancyField = (payload: {
  tenancyId: number;
  field: any;
  value: any;
}) =>
  http.patch(`/tenancy/${payload.tenancyId}/update`, {
    [payload.field]: payload.value,
  });

export const updateTenancyAfterCompletion = (payload: {
  tenancyId: number;
  tenancyBasePayload: TenancyBasePayload;
  tenancyTenantPayload?: Partial<TenancyTenantPayload>[];
}) =>
  http.patch(`tenancy/${payload.tenancyId}/update-tenancy-after-completion`, {
    tenants: payload.tenancyTenantPayload,
    prepaid_rent: payload.tenancyBasePayload.prepaid_rent,
    deposit: payload.tenancyBasePayload.deposit,
    move_in_date: payload.tenancyBasePayload.move_in_date,
    move_out_date: payload.tenancyBasePayload.move_out_date,
  });

export const deleteTenancy = (tenancyId: number, dryRun = false) =>
  http.post(`/tenancy/${tenancyId}/delete`, {
    dry_run: dryRun,
  });

export const setupRent = (
  rentableId: number,
  payload: TenancySetupRentPayload,
) =>
  http.post<Tenancy>(`/tenancy/${rentableId}/setup-rent`, {
    ...payload,
  });

export const addPaymentNote = (paymentRecordId: number, payload: string) =>
  http.patch(`/tenancy/payments/${paymentRecordId}/note`, {
    note: payload,
  });

export const updatePaymentRecord = (data: {
  tenancyId: number;
  paymentRecordId: number;
  payload: TenancyRentEntity[];
}) =>
  http.patch<TenancyRent>(
    `/tenancy/${data.tenancyId}/payments/${data.paymentRecordId}/update`,
    {
      data: data.payload,
    },
  );

export const deletePaymentRecord = (paymentRecordId: number, dryRun = false) =>
  http.post(`/tenancy/payments/${paymentRecordId}/delete`, {
    dry_run: dryRun,
  });

export const updatePaymentStatus = (payload: {
  status: TenancyPaymentStatus;
  paymentRecordId: number;
}) =>
  http.patch(`/tenancy/payments/${payload.paymentRecordId}/status`, {
    status: payload.status,
  });

export const getRentableListing = (rentableId: number) =>
  http.get(`rentable/${rentableId}/listing-page`);

export const deleteRentable = (rentableId: number, dryRun = false) =>
  http.post(`/rentable/delete/${rentableId}/`, {
    dry_run: dryRun,
  });

export const getRentOverviewData = () =>
  http.get<GetRentOverviewResponse>("/tenancy/payments/overview");

export const getRentRecords = (params: GetRentRecordsQueryParams) =>
  http.post<GetRentRecordsResponse>(
    "/tenancy/payments/detail-overview",
    params,
  );

export const getNextPaymentDay = (
  selectedDate: string,
  selectedPaymentProvider: TenancyPaymentType,
) =>
  http.post<{
    next_payment_date: string;
    date_moved: boolean;
  }>("/tenancy/next-payment-date", {
    next_date: selectedDate,
    provider_type: selectedPaymentProvider,
  });

export const getMerchantPlaces = () =>
  http.get<MerchantProvidersResponse>("/tenancy/mobilepay-merchant-places");

export const getRentRegulationDueDateOptions = (tenancyId: number) =>
  http.get<{
    due_dates: {
      date: string;
      available: boolean;
      payment_id: number;
    }[];
  }>(`/tenancy/${tenancyId}/due-date-options`);

export const getStopRentDueDateOptions = (tenancyId: number) =>
  http.get<{
    can_use_todays_date: boolean;
    due_dates: {
      date: string;
      payment_id: number | null;
    }[];
  }>(`/tenancy/${tenancyId}/stop-rent-date-options`);

export const getRentables = async (
  requestPayload: GetRentablesPaginationRequest,
): Promise<GetRentablesPaginationResponse> => {
  const response = await fetchFromAPI("/api/rentable/rentables/", {
    method: "POST",
    body: JSON.stringify({
      search_term: requestPayload.filters.search,
      sorting: requestPayload.filters.sorting,
      descending: requestPayload.filters.descending,
      start_index: requestPayload.start_index,
      limit: requestPayload.limit,
    }),
  });

  if (!response.ok) {
    return Promise.reject("Something went wrong");
  }

  const data = await response.json();

  return {
    data: data.rentables,
    all_count: data.all_count,
    filter_count: data.filter_count,
  };
};

export const getRentableRentOverviewData = (
  rentableId: number,
  params: GetRentableRentOverviewQueryParams,
) =>
  http.post<GetRentableRentOverviewResponse>(
    `rentable/${rentableId}/rent-overview-page`,
    params,
  );

export const getPaymentDetails = (paymentRecordId: number) =>
  http.get<GetPaymentDetailsResponse>(
    `tenancy/payments/${paymentRecordId}/details`,
  );

export const getExpenditures = () =>
  http.get<{ count: number }>("/subscriptions/expenditures/?limit=1");

export const getLocations = (search?: string) =>
  http.post<LocationAutoCompleteResponse>(
    `locations?q=${encodeURIComponent(search ?? "")}`,
    {
      filter: {},
      web: true,
    },
  );

export const getUserPartOfVerifiedCompany = () =>
  http.get<{ is_user_part_of_verified_company: boolean }>(
    "authorize/user/is_user_part_of_verified_company",
  );

export const getAvailableRentalData = () =>
  http.get<{ power_bi_dashboards: PowerBiDashboard[] }>(
    "rental_data/get-available-rental-data-bi-dashboards/",
  );

export const getRentalDataPlan = () =>
  http.get<Plan>("subscription-products/rental-data/get-plan");

export const getLandlordSubscriptionProduct = () =>
  http.get<{ subscription: Subscription; plan: Plan }>(
    "subscription-products/rental-data/details",
  );

export const cancelProductSubscription = (
  subscriptionIds: number[],
  reason: string,
) =>
  http.post("subscription-products/cancel-subscriptions", {
    subscription_ids: subscriptionIds,
    cancellation_reason: reason,
  });

export const reactivateProductSubscription = (subscriptionIds: number[]) =>
  http.post("subscription-products/undo-cancel-subscriptions", {
    subscription_ids: subscriptionIds,
  });

export const getWinbackUpsellPlans = (planId: number) =>
  http.get<{ plans: Plan[] }>(
    `subscription-products/get-winback-plan/${planId}`,
  );

export const activateWinbackSubscription = (
  planId: number,
  subscriptionId: number,
) =>
  http.post<LastCanceledSubscriptionResponse>(
    "subscription-products/accept-winback-plan",
    {
      plan_id: planId,
      subscription_id: subscriptionId,
    },
  );

export const trackCtaButtonClickedByUser = () =>
  http.post("rental_data/track-cta-button-click/");
