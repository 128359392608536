import { useState } from "react";
import { deleteVideoService } from "../services/delete_video_service";

const useVideoEditorFeature = () => {
  const [videoEditorStatus, setVideoEditorStatus] = useState<
    "idle" | "deleting" | "videoDeletedSuccessfully" | "failed"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const deleteVideo = (url: string) => {
    deleteVideoService(url).then((response) => {
      setErrorMessage(response.errorMessage);
      setVideoEditorStatus(
        response.success ? "videoDeletedSuccessfully" : "failed",
      );
    });
  };

  return {
    videoEditorStatus,
    deleteVideo,
    errorMessage,
  };
};

export { useVideoEditorFeature };
