import React from "react";
import { Box, Container, Flex, Text, styled } from "@boligportal/juice";
import { AdCard } from "components/cards/ad_card";
import { Ad } from "components/interfaces/ad";
import { t } from "lib/i18n";
import { FavouriteAdButtonPlacementTracking } from "lib/tracking/events";
import { Loading } from "..";
import { AdGrid } from "../../home_page/popular_listings";
import { ReminderCard } from "./ReminderCard";

const MaxWidthContainer = styled(Box)`
  max-width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: 1500px;
  }
`;

type Props = {
  ads: Ad[];
  unavailablePercent: string;
  sortingSlot: React.ReactNode;
  showReminderCard: boolean;
  isLoading: boolean;
};

export const MyFavouriteAds = ({
  ads,
  unavailablePercent,
  showReminderCard,
  sortingSlot,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <Loading />;
  }

  if (ads.length === 0) {
    return (
      <Flex
        align="center"
        justify="center"
        height="100%"
        m="auto"
      >
        <Text
          color="muted"
          size="small"
        >
          {t("my_favourite_ads.empty.filtered")}
        </Text>
      </Flex>
    );
  }

  const renderAds = () =>
    ads.map((ad, index) => (
      <React.Fragment key={ad.id}>
        {showReminderCard && index === 1 && (
          <ReminderCard unavailablePercent={unavailablePercent} />
        )}
        <AdCard
          isExtendedDetails
          key={ad.id}
          ad={ad}
          placement={FavouriteAdButtonPlacementTracking.my_favourite_ads}
        />
      </React.Fragment>
    ));

  return (
    <Box my={2}>
      <Container fluid>
        <MaxWidthContainer>
          <Flex
            justify="end"
            align="center"
            pb={2}
          >
            {sortingSlot}
          </Flex>
          <AdGrid>{renderAds()}</AdGrid>
        </MaxWidthContainer>
      </Container>
    </Box>
  );
};
