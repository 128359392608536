import { PureComponent } from "react";
import { getCookiesConsent } from "lib/cookies";
import { setCookie } from "lib/utils";

export class MarketingCookiesComponent extends PureComponent {
  componentDidMount() {
    window.addEventListener("CookieInformationConsentGiven", () => {
      if (getCookiesConsent("cookie_cat_statistic")) {
        this.parse_utm_tags();
      }
    });

    if (window.CookieInformation == "disabled") {
      this.parse_utm_tags();
    }
  }

  private parse_utm_tags = () => {
    const utm_params = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "gclid",
      "dclid",
    ];
    const paramsString = location.search;
    const searchParams = new URLSearchParams(paramsString);
    utm_params.forEach((item) => {
      const val = searchParams.get(item);
      if (val) {
        setCookie(item, val);
      }
    });

    const { referrer } = document;
    if (
      referrer &&
      !referrer.includes(window.location.hostname) &&
      !referrer.includes("payment.quickpay.net")
    ) {
      setCookie("referrer_url", referrer);
    }
  };

  render() {
    return null;
  }
}
